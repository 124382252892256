const HeaderMenu = ({ open }) => {
  return (
    <>
      <div className={`menu-container ${open ? 'active' : ''}`} id="menu">
        <a className="menu-item" href="/">
          Home
        </a>{' '}
        |
        <a className="menu-item" href="/services">
          Services
        </a>{' '}
        |
        <a className="menu-item" href="/aboutus">
          About Us
        </a>{' '}
        |
        <a className="menu-item" href="/contact">
          Contact
        </a>
      </div>
      <div className={`menu-container-line-2 ${open ? 'active' : ''}`} id="menu">
        <a className="menu-item" href="/training">
          Trainings
        </a>{' '}
        |
        <a className="menu-item" href="/blog">
          Blogs
        </a>{' '}
        |
        <a className="menu-item" href="/news">
          News
        </a>
      </div>
    </>
  );
};

export default HeaderMenu;
