import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userLabel, setUserLabel] = useState(localStorage.getItem('userLabel'));

  useEffect(() => {
    const storedIsAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
    const storedUserLabel = localStorage.getItem('userLabel') || '';
    setIsAuthenticated(storedIsAuthenticated);
    setUserLabel(storedUserLabel);
  }, []);

  useEffect(() => {
    localStorage.setItem('isAuthenticated', isAuthenticated.toString());
    localStorage.setItem('userLabel', userLabel);
  }, [isAuthenticated, userLabel]);

  const authenticateUser = (isAuth, label, email) => {
    localStorage.setItem('isAuthenticated', isAuth);
    localStorage.setItem('userLabel', label);
    localStorage.setItem('userEmail', email);
    setIsAuthenticated(isAuth);
    setUserLabel(label);
  };

  const updateUserName = (newName) => {
    localStorage.setItem('userLabel', newName);
    setUserLabel(newName);
  };

  const value = { isAuthenticated, userLabel, authenticateUser, updateUserName };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('usePersistAuth must be used within an AuthProvider');
  }
  return context;
};
