import React, { useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import awsExports from '../config/aws-exports';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

Amplify.configure({
  Auth: {
    Cognito: {
      region: awsExports.REGION,
      userPoolId: awsExports.USER_POOL_ID,
      userPoolClientId: awsExports.USER_POOL_APP_CLIENT_ID,
      loginWith: {
        email: true,
        name: true,
      },
    },
  },
});

export function MyAuthenticator({ user }) {
  const navigate = useNavigate();

  const { authenticateUser } = useAuth();

  useEffect(() => {
    if (user) {
      authenticateUser(true, '', '');
      navigate('/training');
    }
  }, [navigate, user, authenticateUser]);

  return <></>;
}

export default withAuthenticator(MyAuthenticator);
