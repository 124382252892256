function getEnvVariable(suffix) {
  const env = process.env.REACT_APP_ENV || process.env.NODE_ENV;
  const varName = `REACT_APP_${suffix}_${env}`;
  return process.env[varName];
}

const awsExports = {
  REGION: 'eu-central-1',
  API_URL: getEnvVariable('API_URL'),
  GET_SANDBOX_API_URL: getEnvVariable('GET_SANDBOX_API_URL'),
  USER_PREFERENCES_API_URL: getEnvVariable('USER_PREFERENCES_API_URL'),
  CERTIFICATES_API_URL: getEnvVariable('CERTIFICATES_API_URL'),
  USER_POOL_ID: getEnvVariable('USER_POOL_ID'),
  USER_POOL_APP_CLIENT_ID: getEnvVariable('USER_POOL_APP_CLIENT_ID'),
  MATERIAL_S3_BUCKET: getEnvVariable('MATERIAL_S3_BUCKET'),
  BLOG_S3_BUCKET: getEnvVariable('BLOG_S3_BUCKET'),
  NEWS_S3_BUCKET: getEnvVariable('NEWS_S3_BUCKET'),
  GAME_SCORE: getEnvVariable('GAME_SCORE'),
  GAME_STATUS: getEnvVariable('GAME_STATUS'),
};

export default awsExports;
