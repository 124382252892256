const UserMenu = () => {
  return (
    <>
      <div className={`profile-container`} id="profile">
        <a className="menu-item" href="/preferences">
          Preferences
        </a>{' '}
        |{' '}
        <a className="menu-item" href="/certificates">
          My certificates
        </a>
      </div>
    </>
  );
};

export default UserMenu;
