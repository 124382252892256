import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'aws-amplify/auth';
import HeaderMenu from './HeaderMenu';
import UserMenu from './UserMenu';
import '../css/HeaderMenu.css';
import '../css/UserMenu.css';
import '../css/PageTemplate.css';
import { useAuth } from '../contexts/AuthContext';
import { fetchAuthSession } from 'aws-amplify/auth';
import awsExports from '../config/aws-exports';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
  const navigate = useNavigate();
  const { isAuthenticated, userLabel, authenticateUser } = useAuth();
  const [sandboxSignInUrl, setSandboxSignInUrl] = useState('');
  const [accountId, setAccountId] = useState('');

  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isHeaderMenuOpen, setIsHeaderMenuOpen] = useState(false);
  const isUserMenuOpenRef = useRef(isUserMenuOpen);
  const isHeaderMenuOpenRef = useRef(isHeaderMenuOpen);

  const [initialLoop, setInitialLoop] = useState(true);
  const [playVideo, setPlayVideo] = useState(true);
  const [displayVideo, setDisplayVideo] = useState(0);
  const [imageOpacity, setImageOpacity] = useState(0);
  const [isVideoVisible, setIsVideoVisible] = useState(true);
  const [videoError, setVideoError] = useState(false);
  const videoRef = useRef(null);

  const [videoSources] = useState([
    'https://syntaxnebula-media.s3.eu-central-1.amazonaws.com/syntaxnebula_banner_left_up.mp4',
    'https://syntaxnebula-media.s3.eu-central-1.amazonaws.com/syntaxnebula_banner_right_up.mp4',
  ]);
  const [videoSource, setVideoSource] = useState(videoSources[0]);
  const [videoIndex, setVideoIndex] = useState(0);

  const updateVideoSource = () => {
    if (isVideoVisible) {
      const nextIndex = (videoIndex + 1) % videoSources.length;
      setVideoIndex(nextIndex);
      setVideoSource(videoSources[nextIndex]);
      setVideoError(false);
    }
  };

  const playVideoSafely = async (videoElement) => {
    try {
      await videoElement.play();
    } catch (error) {
      console.error('Error trying to play video:', error.message);
      setVideoError(true);
    }
  };

  const pauseVideoSafely = async (videoElement) => {
    try {
      await videoElement.pause();
    } catch (error) {
      console.error('Error trying to pause video:', error.message);
      setVideoError(true);
    }
  };

  const loadVideoSafely = async (videoElement) => {
    try {
      await videoElement.load();
    } catch (error) {
      console.error('Error trying to load video:', error.message);
      setVideoError(true);
    }
  };

  const handleVideoEnd = async () => {
    const videoElement = videoRef.current;
    if (isVideoVisible) {
      setImageOpacity(1);
      setTimeout(() => {
        if (videoElement) {
          updateVideoSource();
          pauseVideoSafely(videoElement);
          loadVideoSafely(videoElement);
          playVideoSafely(videoElement);
          videoElement.playbackRate = 0.5;
          setImageOpacity(0);
          setPlayVideo(true);
        }
      }, 20000);
    }
  };

  useEffect(() => {
    if (initialLoop && isVideoVisible) {
      setImageOpacity(1);
      setPlayVideo(false);
      setVideoSource(videoSources[0]);
      setTimeout(() => {
        if (videoRef.current) {
          playVideoSafely(videoRef.current);
          videoRef.current.playbackRate = 0.5;
          setImageOpacity(0);
          setDisplayVideo(1);
        }
      }, 5000);
    }
    setInitialLoop(false);
  }, [isVideoVisible, initialLoop, videoSources]);

  useEffect(() => {
    const videoElement = videoRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVideoVisible(true);
          playVideoSafely(videoElement);
        } else {
          setIsVideoVisible(false);
          pauseVideoSafely(videoElement);
        }
      },
      {
        threshold: 0.25,
      },
    );

    if (videoElement) {
      observer.observe(videoElement);
    }

    return () => {
      if (videoElement) {
        observer.unobserve(videoElement);
      }
    };
  }, []);

  async function handleSignOut() {
    try {
      await signOut();
      authenticateUser(false, '', '');
      localStorage.setItem('isAuthenticated', false);
      navigate('/');
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  useEffect(() => {
    function base64UrlDecode(input) {
      // Replace characters according to base64url specifications
      let base64 = input.replace(/-/g, '+').replace(/_/g, '/');

      // Pad string with '=' characters to make the length a multiple of 4
      switch (base64.length % 4) {
        case 0:
          break; // No pad chars in this case
        case 2:
          base64 += '==';
          break; // Two pad chars
        case 3:
          base64 += '=';
          break; // One pad char
        default:
          throw new Error('Illegal base64url string!');
      }

      const raw = window.atob(base64);
      return decodeURIComponent(
        raw
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join(''),
      );
    }

    function decodeJWT(token) {
      const parts = token.split('.');
      if (parts.length !== 3) {
        throw new Error('The token is invalid');
      }

      const header = JSON.parse(base64UrlDecode(parts[0]));
      const payload = JSON.parse(base64UrlDecode(parts[1]));

      return { header, payload };
    }

    async function decodeAndAuthenticate() {
      try {
        const session = await fetchAuthSession();
        if (!session || !session.tokens || !session.tokens.idToken) {
          authenticateUser(false, '', '');
          return;
        }

        const idToken = session.tokens.idToken.toString();
        const decodedToken = decodeJWT(idToken);
        const username = userLabel ? userLabel : decodedToken.payload.name;
        const email = decodedToken.payload.email;
        authenticateUser(true, username, email);
      } catch (error) {
        console.error('Error checking auth status:', error);
        authenticateUser(false, '', '');
      }
    }

    async function getSandboxAccountForUser() {
      if (localStorage.getItem('isAuthenticated') === 'false' || localStorage.getItem('isAuthenticated') === null) {
        return;
      }
      const apiUrl = awsExports.GET_SANDBOX_API_URL;
      const fullUrl = `${apiUrl}sandbox`;
      try {
        const { idToken } = (await fetchAuthSession()).tokens ?? {};
        const response = await fetch(fullUrl, {
          method: 'GET',
          headers: {
            Authorization: idToken.toString(),
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        } else if (response.status === 204) {
          setSandboxSignInUrl('');
          return;
        }
        const data = await response.json();
        setAccountId(data.accounts);
        setSandboxSignInUrl('https://console.aws.amazon.com');
      } catch (error) {
        setSandboxSignInUrl('');
        console.error('There has been a problem with sandbox account information retrieval:', error);
      }
    }

    decodeAndAuthenticate();
    getSandboxAccountForUser();
  }, [isAuthenticated, authenticateUser, userLabel]);

  // Menu handling

  useEffect(() => {
    isUserMenuOpenRef.current = isUserMenuOpen;
    isHeaderMenuOpenRef.current = isHeaderMenuOpen;

    if (isUserMenuOpen || isHeaderMenuOpen) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isUserMenuOpen, isHeaderMenuOpen]);

  const handleClickOutside = (event) => {
    const userIcon = document.getElementById('user-icon');
    const hamburger = document.getElementById('hamburger');

    if (userIcon.contains(event.target) || hamburger.contains(event.target)) {
      return;
    }

    if (isUserMenuOpenRef.current || isHeaderMenuOpenRef.current) {
      setIsUserMenuOpen(false);
      setIsHeaderMenuOpen(false);
    }
  };

  const toggleUserMenu = () => {
    setIsUserMenuOpen(!isUserMenuOpen);
    setIsHeaderMenuOpen(false);
  };

  const toggleHeaderMenu = () => {
    setIsHeaderMenuOpen(!isHeaderMenuOpen);
    setIsUserMenuOpen(false);
  };

  const getUserIcon = () => {
    if (isUserMenuOpen) {
      return '/images/user_clicked.png';
    }
    return '/images/user.png';
  };

  const getHoverUserIcon = () => {
    if (isUserMenuOpen) {
      return '/images/user_hover_clicked.png';
    }
    return '/images/user_hover.png';
  };

  return (
    <header id="siteHeader" className="site-header">
      <video
        muted
        ref={videoRef}
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          objectFit: 'cover',
          zIndex: 0,
          display: displayVideo ? 'block' : 'none',
        }}
        onEnded={handleVideoEnd}
        autoPlay={playVideo}
        onError={() => {
          console.error('Error loading or playing video');
          setVideoError(true);
        }}
      >
        <source src={videoSource} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <img
        src="/images/syntaxnebula_banner_upscaled.webp"
        alt="Syntax Nebula header"
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          objectFit: 'cover',
          zIndex: 0,
          opacity: videoError ? 1 : imageOpacity,
          transition: 'opacity 5s ease',
          filter: 'brightness(90%)',
        }}
        className="responsive-banner"
      />

      {isAuthenticated ? (
        <>
          <span className="user-label"> Welcome, {userLabel}!</span>
          <button onClick={handleSignOut} className="signout-button">
            Sign out
          </button>
          <button onClick={toggleUserMenu} className="user-icon" id="user-icon">
            <img
              src={getUserIcon()}
              alt="User Icon"
              onMouseEnter={(e) => (e.target.src = getHoverUserIcon())}
              onMouseLeave={(e) => (e.target.src = getUserIcon())}
            ></img>
          </button>

          {sandboxSignInUrl && (
            <>
              <span className="sandbox-label">
                {' '}
                Sandbox account: [
                {Array.isArray(accountId)
                  ? accountId.map((id, index) => (
                      <span key={id}>
                        {index > 0 && ', '}
                        {id}
                      </span>
                    ))
                  : 'None'}
                ]{' '}
              </span>
              <a href={sandboxSignInUrl} className="sandbox-button" target="_blank" rel="noopener noreferrer">
                {' '}
                Console{' '}
              </a>
            </>
          )}
          {isUserMenuOpen && <UserMenu />}
        </>
      ) : (
        <Link to="/signin" className="signin-button">
          Sign in
        </Link>
      )}
      <a href="/">
        <img className="logo" src="/images/logo.png" alt="Logo" />
      </a>
      <div onClick={toggleHeaderMenu} className={`hamburger ${isHeaderMenuOpen ? 'active' : ''}`} id="hamburger">
        {isHeaderMenuOpen ? <FontAwesomeIcon icon={faTimes} /> : <FontAwesomeIcon icon={faBars} />}
      </div>
      <HeaderMenu open={isHeaderMenuOpen} />
    </header>
  );
};

export default Header;
